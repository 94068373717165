<template>
    <div>
      <ServerError v-if="ServerError" />
        <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
        <v-layout wrap pt-5 px-5>
            <v-layout wrap>
                <v-flex xs12 text-left>
                    <span style="color:#000; font-family: poppinsbold; font-size:25px">Enquiry View</span>
                </v-flex>
            </v-layout>
            <v-flex xs12 pt-5>
                <v-card>
                    <v-layout wrap justify-start>
                        <v-flex xs6 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs4 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Name </span>
                                </v-flex>
                                <v-flex xs8 text-left v-if="enquiry.name">
                                    <span style="color:black;font-family: poppinsmedium;">- {{ enquiry.name }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex>
                        <v-flex xs6 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs4 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Email </span>
                                </v-flex>
                                <v-flex xs8 text-left v-if="enquiry.email">
                                    <span style="color:black;font-family: poppinsmedium;">- {{ enquiry.email }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex>
                        <v-flex xs6 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs4 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Phone </span>
                                </v-flex>
                                <v-flex xs8 text-left v-if="enquiry.phoneNo">
                                    <span style="color:black;font-family: poppinsmedium;">- {{ enquiry.phoneNo }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex>
                        <!-- <v-flex xs6 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs4 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Location </span>
                                </v-flex>
                                <v-flex xs8 text-left v-if="enquiry.location">
                                    <span style="color:black;font-family: poppinsmedium;">- {{ enquiry.location }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex> -->
                        <v-flex xs6 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs4 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Date </span>
                                </v-flex>
                                <v-flex xs8 text-left v-if="enquiry.create_date">
                                    <span style="color:black;font-family: poppinsmedium;">- {{
                                        enquiry.create_date.slice(0, 10) }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex>
                        <v-flex xs12 px-5 py-5>
                            <v-layout wrap>
                                <v-flex xs3 sm2 md2 text-left>
                                    <span style="color:black;  font-family: poppinssemibold;">Message </span>
                                </v-flex>
                                <v-flex xs9 sm10 md10 text-left v-if="enquiry.message">
                                    <span style="color:black;font-family: poppinsmedium;">- {{ enquiry.message }} </span>
                                </v-flex>
                            </v-layout>
                            <hr>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            appLoading: false,
            ServerError: false,
            enquiry: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.appLoading = true;
            axios({
                url: "/admin/contactUs/view",
                method: "POST",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    id: this.$route.query.id
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    this.enquiry = response.data.data;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>